
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { reactive } from '@vue/composition-api'

@Component<CheckoutOverviewBlik>({
  name: 'CheckoutOverviewBlik'
})
export default class CheckoutOverviewBlik extends Vue {
  @Prop({ type: String, default: '' })
  public readonly blikErrorMessage!: string

  $refs!: {
    digits: HTMLInputElement[]
  }

  digits = reactive<string[]>(new Array(6 + 1).fill(''))

  get code (): string {
    return this.digits.join('')
  }

  get isCodeValid (): boolean {
    return this.code.length === this.digits.length - 1
  }

  get currentDigit (): number {
    const index = this.digits.findIndex(digit => digit === '')

    return index === -1 ? this.digits.length - 1 : index
  }

  isInvisibleDigit (idx: number): boolean {
    return idx === this.digits.length - 1
  }

  setCurrentDigit (digit: number = this.currentDigit): void {
    const digitElement = this.$refs.digits[digit]

    if (digitElement) {
      digitElement.focus()
    }
  }

  onInput (): void {
    this.$nextTick(() => {
      this.setCurrentDigit()
    })
  }

  onFocus (idx: number): void {
    if (this.currentDigit === idx) {
      return
    }

    this.$nextTick(() => {
      this.setCurrentDigit()
    })
  }

  onKeydown (e: KeyboardEvent): void {
    if (e.key === 'Backspace') {
      const digit = this.currentDigit

      if (this.digits[digit] === '') {
        this.$set(this.digits, digit - 1, '')

        this.$nextTick(() => {
          this.setCurrentDigit()
        })
      }
    }
  }

  onPaste (e: ClipboardEvent): void {
    const pastedData = e.clipboardData?.getData('Text') || ''

    for (let i = 0; i < pastedData.length; i++) {
      if (i >= this.digits.length - 1) {
        break
      }
      this.$set(this.digits, i, pastedData[i])
    }

    this.$nextTick(() => {
      this.setCurrentDigit(this.digits.length - 1)
    })

    e.preventDefault()
  }

  @Watch('code')
  onCodeChange (): void {
    if (this.isCodeValid) {
      this.$emit('code', this.code)
    } else {
      this.$emit('code', undefined)
    }
  }
}
